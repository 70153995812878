import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import Navbar from '../Navbars';
import Footer from '../Footer';
import './comingSoonStyles.css'; // Importer le fichier CSS pour les styles supplémentaires

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <Navbar />
      <Box className="coming-soon-content" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="h1" className="coming-soon-text">
          Coming soon
        </Typography>
        <Typography variant="h6" className="coming-soon-subtext">
          Stay tuned for something amazing!
        </Typography>
        <Button variant="outlined" href="https://shorty-workshop.be">
          Return ShortyWorkShop
        </Button>
      </Box>
      <Footer />
    </div>
  );
}

export default ComingSoon;
